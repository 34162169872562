import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import {PortableText} from '@portabletext/react'
import {getGatsbyImageData} from 'gatsby-source-sanity'
import "./news-post.scss";

const newsPostTemplate = props => {
  const { data } = props
  const post = data && data.post
  const sanityConfig = {projectId: process.env.SANITY_PROJECT_ID, dataset: process.env.SANITY_PROJECT_DATASET}

  console.log("data", data)
  return (
    <Layout>
      <section className="news-post__header">
        <div className="news-post__header-overlay"></div>
        <GatsbyImage
          className="h-full"
          image={post.featuredImage.asset.gatsbyImageData}
          alt={post.featuredImage.asset.altText}
        />
        <div className="news-post__header-content">
          <h1>{post.title}</h1>
        </div>
      </section>

      <section className="news-post__main">
        <div className="site-container">
          <div className="prose mx-auto">
            <PortableText 
              value={post._rawPortableText}
              components={{
                types: {
                  image: ({value}) => (
                    <GatsbyImage
                      image={getGatsbyImageData(value.asset._ref, {maxWidth: 1024}, sanityConfig)}
                    />
                  )
                }
              }}
              onMissingComponent={false}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default newsPostTemplate

export const query = graphql`
  query NewsPostTemplateQuery($id: String!) {
    post: sanityNews(id: { eq: $id }) {
      id
      title
      publishedAt
      slug {
        current
      }
      featuredImage {
        _key
        _type
        asset {
          altText
          gatsbyImageData
        }
      }
      _rawPortableText
    }
  }
`
